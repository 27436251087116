import { IAuxProps } from '../../shared/interfaces/IAuxProps';
import './Paragraph.scss';
import React from 'react';

export interface IParagraphProps extends IAuxProps, React.HTMLAttributes<HTMLHeadingElement> {
    fontWeight?: 'thin' | 'regular' | 'medium' | 'semi-bold' | 'bold' | 'extra-bold';
    fontFamily: 'heading' | 'content';
}

const Paragraph = ({ fontFamily, fontWeight, children, ...props }: IParagraphProps) => {
    return (
        <p
            {...props}
            className={`${props.className} paragraph paragraph--${fontFamily} paragraph--${fontWeight}`}
        >
            {children}
        </p>
    )
}

export default Paragraph;
