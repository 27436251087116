import { v4 as uuidv4 } from 'uuid';
import { Link } from 'react-scroll'
import { ENavbarRef } from '../../shared/enums/ENavbarRef';
import { Twirl as Hamburger } from 'hamburger-react';
import './Navbar.scss';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faSquarePhone } from '@fortawesome/free-solid-svg-icons';
import QuokkaSchoolLogo from './../../assets/quokka-school-logo.svg';

export interface INavbarItem {
    id: string;
    displayValue: string;
    to: ENavbarRef;
}

const NAVBAR_ITEMS: INavbarItem[] = [
    {
        id: uuidv4(),
        displayValue: 'Start',
        to: ENavbarRef.Start,
    },
    {
        id: uuidv4(),
        displayValue: 'Zakres zajęć',
        to: ENavbarRef.Scope,
    },
    {
        id: uuidv4(),
        displayValue: 'Oferta',
        to: ENavbarRef.Offert,
    },
    {
        id: uuidv4(),
        displayValue: 'Opinie',
        to: ENavbarRef.Opinions,
    },
    {
        id: uuidv4(),
        displayValue: 'FAQ',
        to: ENavbarRef.Answers,
    },
    {
        id: uuidv4(),
        displayValue: 'Kontakt',
        to: ENavbarRef.Contact,
    },
]

const Navbar = () => {
    const [isOpen, setOpen] = useState(false);

    const createListItems = () => NAVBAR_ITEMS.map((item) =>
        <li key={item.id} className="list__item item">
            <Link
                onClick={() => setOpen(false)}
                className="item__link link"
                activeClass="item__link--active"
                to={item.to}
                spy={true}
                smooth={true}
                duration={500}
                offset={-80}
            >{ item.displayValue }</Link>
        </li>
    );

    return (
        <nav className={`navbar ${isOpen ? 'navbar--is-open' : ''}`}>
            <div className="navbar__wrapper">
                <div className="navbar__contact">
                    <div className="container">
                        <a href="tel:796526375" className="contact__item">
                            <div className="item__icon">
                                <FontAwesomeIcon icon={faSquarePhone}/>
                            </div>
                            <span className="item__label">
                                796 526 375
                            </span>
                        </a>
                        <a href="mailto:kontakt@quokka-school.pl" className="contact__item">
                            <div className="item__icon">
                                <FontAwesomeIcon icon={faEnvelope}/>
                            </div>
                            <span className="item__label">
                                kontakt@quokka-school.pl
                            </span>
                        </a>
                    </div>
                </div>
                <div className="container">
                    <img
                        src={QuokkaSchoolLogo}
                        alt="Quokka School Logo - Szkoła Programowania i Mentoring"
                        width={140}
                    />

                    <Hamburger
                        color="#4B4B4B"
                        size={25}
                        toggled={isOpen}
                        toggle={setOpen}
                        duration={0.2}
                    />

                    <ul className="navbar__list list">
                        {createListItems()}
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;
