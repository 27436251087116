import React, { useEffect, useState } from 'react';
import Heading from '../../heading/Heading';
import Paragraph from '../../paragraph/Paragraph';
import './AboutSection.scss';
import Button from '../../button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedinIn, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faSquarePhone } from '@fortawesome/free-solid-svg-icons';

const AboutSection = () => {
    const [introVideoSrc, setIntroVideoSrc] = useState<string>('');

    useEffect(() => {
        fetch('data/intro-video.json')
            .then((res) => res.json())
            .then((data) => setIntroVideoSrc(data.src))
    }, []);

    return (
        <div className="about-section">
            <div className="container container--shrink">
                <Heading className="about-section__title" tag="h3" size="lg" data-aos="fade-up">Kim jesteśmy?</Heading>
                <Paragraph className="about-section__paragraph" fontFamily="heading" fontWeight="thin" data-aos="fade-up" data-aos-delay="150">Kształcimy
                    przyszłych programistów! 🚀🔥</Paragraph>
                <Paragraph className="about-section__paragraph" fontFamily="content" data-aos="fade-up" data-aos-delay="300">Pomożemy Ci niezależnie od tego,
                    czy dopiero rozpoczynasz swoją przygodę z programowaniem w szkole podstawowej 🏫, przygotowywuje się
                    do matury lub egzaminu zawodowego 👨‍💻 czy też jesteś
                    studentem 🎓 chcącym zostać programistą 💻. Prowadzimy zajęcia dostosowane do każdego poziomu
                    zaawansowania 📈. Wierzymy, że nauka programowania powinna być interaktywna i praktyczna 🔮🪄. Dlatego
                    też nasze zajęcia skupiają się na rozwiązywaniu rzeczywistych problemów i tworzeniu funkcjonalnych
                    projektów ⚗️.</Paragraph>

                <div className="about-section__social social">
                    <Button type='icon' data-aos="fade-up" data-aos-delay="350">
                        <a href="https://github.com/QuokkaSchool" target="_blank">
                            <FontAwesomeIcon icon={faGithub}/>
                        </a>
                    </Button>
                    <Button type='icon' data-aos="fade-up" data-aos-delay="400">
                        <a href="https://www.linkedin.com/company/quokka-school" target="_blank">
                            <FontAwesomeIcon icon={faLinkedinIn}/>
                        </a>
                    </Button>
                    <Button type='icon' data-aos="fade-up" data-aos-delay="450">
                        <a href="tel:796526375">
                            <FontAwesomeIcon icon={faSquarePhone}/>
                        </a>
                    </Button>
                    <Button type='icon' data-aos="fade-up" data-aos-delay="550">
                        <a href="mailto:kontakt@quokka-school.pl">
                            <FontAwesomeIcon icon={faEnvelope}/>
                        </a>
                    </Button>
                    <Button type='icon' data-aos="fade-up" data-aos-delay="600">
                        <a href="https://www.youtube.com/channel/UC_53_peEgbXWinwj1mTkRyQ" target="_blank">
                            <FontAwesomeIcon icon={faYoutube}/>
                        </a>
                    </Button>
                </div>

                <div className="about-section__video">
                    <div className="wrapper">
                        <iframe src={introVideoSrc}
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen data-aos="fade-up"></iframe>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutSection;
