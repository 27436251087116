import React, { useEffect, useRef, useState } from 'react';
import './OpinionsSection.scss';
import Heading from '../../heading/Heading';
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

import { EffectCoverflow, Navigation, Pagination } from 'swiper/modules';
import Paragraph from '../../paragraph/Paragraph';
import Button from '../../button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faStar } from '@fortawesome/free-solid-svg-icons';

export interface IOpinion {
    content: string;
    author: string;
}

const OpinionsSection = () => {
    const [opinions, setOpinions] = useState<IOpinion[]>([]);

    useEffect(() => {
        fetch('data/opinions.json')
            .then((res) => res.json())
            .then((data) => setOpinions(data))
    }, []);

    const swiperRef = useRef();

    return (
        <div className="opinions-section container">
            <div className="opinions-section__text">
                <Heading className="opinions-section__title" tag="h3" size="lg" data-aos="fade-up" data-aos-delay="150">Co o nas mówią?</Heading>
                <Paragraph className="about-section__paragraph" fontFamily="content" data-aos="fade-up" data-aos-delay="300">Opinie naszych uczniów są dla nas
                    niezmiernie ważne. 🔥❤️ Jesteśmy dumni z naszych uczniów i ich osiągnięć! 😉 Ich sukcesy to nasza
                    największa motywacja do dalszej pracy. Z dumą
                    prezentujemy opinie naszych uczniów, które są dla nas najlepszą rekomendacją. 👑🎉 Dołącz do naszej
                    społeczności i odkryj świat programowania razem z nami! ✨</Paragraph>

                <div className="opinions-section__buttons">
                    <Button clickAction={() => window.open('https://g.page/r/CazDdQwLqzdAEBM/review', '_blank')} type="fill" data-aos="fade-up" data-aos-delay="450">Oceń nas</Button>
                    <div>
                        {/*// @ts-ignore*/}
                        <Button clickAction={() => swiperRef.current.slidePrev()} type='icon' data-aos="fade-up" data-aos-delay="500">
                            <FontAwesomeIcon icon={faChevronLeft}/>
                        </Button>
                        {/*// @ts-ignore*/}
                        <Button clickAction={() => swiperRef.current.slideNext()} type='icon' data-aos="fade-up" data-aos-delay="550">
                            <FontAwesomeIcon icon={faChevronRight}/>
                        </Button>
                    </div>
                </div>
            </div>

            <div className="opinions-section__slider" data-aos="fade-up" data-aos-delay="300">
                <Swiper
                    className="opinions-section__swiper"
                    effect={'coverflow'}
                    grabCursor={true}
                    centeredSlides={true}
                    slidesPerView={'auto'}
                    coverflowEffect={{
                        rotate: 50,
                        stretch: 0,
                        depth: 100,
                        modifier: 1,
                        slideShadows: true,
                    }}
                    navigation={false}
                    pagination={{
                        type: 'bullets'
                    }}
                    modules={[EffectCoverflow, Pagination, Navigation]}
                    onSwiper={(swiper: any) => {
                        swiperRef.current = swiper;
                    }}
                >
                    {opinions.map((item, index) =>
                        <SwiperSlide key={index} className="opinions-section__slide">
                            <div className="opinions-section__content">
                                <p>{ item.content }</p>
                            </div>
                            <div className="opinions-section__info">
                                <div className="opinions-section__stars">
                                    <FontAwesomeIcon icon={faStar}/>
                                    <FontAwesomeIcon icon={faStar}/>
                                    <FontAwesomeIcon icon={faStar}/>
                                    <FontAwesomeIcon icon={faStar}/>
                                    <FontAwesomeIcon icon={faStar}/>
                                </div>
                                <p className="opinions-section__author">{ item.author }</p>
                            </div>
                        </SwiperSlide>
                    )}
                </Swiper>
            </div>
        </div>
    );
}

export default OpinionsSection;
