import React from 'react';
import Heading from '../heading/Heading';
import './CardScope.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faUserGroup } from '@fortawesome/free-solid-svg-icons';
import Button from '../button/Button';

export interface ICardScopeProps {
    index: number;
    image: string;
    imageAlt: string;
    title: string;
    maxGroupCount: number;
    meetingCountPerWeek: number;
    meetingTime: number;
    description: string[];
    pdfLink: string;
}

const CardScope = ({
    index,
    image,
    imageAlt,
    title,
    maxGroupCount,
    meetingCountPerWeek,
    meetingTime,
    description,
    pdfLink,
}: ICardScopeProps) => {
    return (
        <div className="card-scope" data-aos="fade-up" data-aos-delay={150 * (index + 1)}>
            <img className="card-scope__image" src={image} alt={imageAlt}/>

            <div className="card-scope__content">
                <Heading className="card-scope__title" tag="h4" size="sm">{title}</Heading>

                <ul className="card-scope__list">
                    <li className="card-scope__item">
                        <div className="card-scope__icon">
                            <FontAwesomeIcon icon={faUserGroup}/>
                        </div>
                        <span className="card-scope__label">
                            Max. ilość osób <span className="card-scope__accent">({maxGroupCount})</span>
                        </span>
                    </li>
                    <li className="card-scope__item">
                        <div className="card-scope__icon">
                            <FontAwesomeIcon icon={faCalendar}/>
                        </div>
                        <span className="card-scope__label">
                            {meetingCountPerWeek}x w tygodniu <span
                            className="card-scope__accent">({meetingTime} min.)</span>
                        </span>
                    </li>
                </ul>

                <ul className="card-scope__description-list">
                    {description.map((item, index) =>
                        <li key={index} className="card-scope__description-item">{item}</li>
                    )}
                </ul>

                <div className="card-scope__spacer"></div>

                <Button type="stroked"
                        clickAction={() => window.open(pdfLink, '_blank')}
                        style={{
                            display: 'block',
                            width: '100%',
                            marginTop: '2rem'
                        }}>
                    Dowiedz się więcej
                </Button>
            </div>
        </div>
    );
}

export default CardScope;
