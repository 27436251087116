import React from 'react';
import { Element } from 'react-scroll'
import { IAuxProps } from '../../shared/interfaces/IAuxProps';
import { ENavbarRef } from '../../shared/enums/ENavbarRef';
import './Section.scss';

export interface ISectionProps extends IAuxProps {
    name: ENavbarRef;
}

const Section = ({ name, children }: ISectionProps) => {
    return (
        <Element name={name}>
            <section className={`section section__${name}`}>
                { children }
            </section>
        </Element>
    );
}

export default Section;
