import React from 'react';
import './StartSection.scss';
import Button from '../../button/Button';
import Heading from '../../heading/Heading';
import Photo from '../../../assets/hero-photo.png';
import { Link, scroller } from 'react-scroll';
import { ENavbarRef } from '../../../shared/enums/ENavbarRef';

const StartSection = () => {
    return (
        <div className="start-section container">
            <div className="start-section__hero hero">
                <div className="hero__column hero__column--content content">
                    <div className="content__badge badge" data-aos="fade-up">
                        <p className="badge__item">Mentoring</p>
                        <p className="badge__item">Matura</p>
                        <p className="badge__item">Egzaminy</p>
                    </div>

                    <div className="content__heading heading">
                        <Heading className="heading__subtitle" tag="h2" size="md" fontWeight="thin" data-aos="fade-up" data-aos-delay="150">Quokka School</Heading>
                        <Heading className="heading__title" tag="h1" size="xl" data-aos="fade-up" data-aos-delay="300">Pomagamy zostać<br/> Programistą!</Heading>
                    </div>

                    <div className="content__actions actions">
                        <Button data-aos="fade-up" data-aos-delay="450" clickAction={() => scroller.scrollTo(ENavbarRef.Scope, {
                            smooth: true,
                            offset: -80
                        })}>Zakres zajęć</Button>
                        <Button data-aos="fade-up" data-aos-delay="600" clickAction={() => scroller.scrollTo(ENavbarRef.Contact, {
                            smooth: true,
                            offset: -80
                        })} type="stroked">Zapisz się</Button>
                    </div>
                </div>

                <div className="hero__column hero__column--photo photo">
                    <img data-aos="zoom-out" className="photo__img" src={Photo} alt="Quokka School Logo - Szkoła Programowania i Mentoring"/>
                </div>
            </div>
        </div>
    );
}

export default StartSection;
