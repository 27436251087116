import React, { useEffect, useState } from "react";
import Heading from "../../heading/Heading";
import './AnswersSection.scss';
import CardCollapse from "../../card-collapse/CardCollapse";
import { IOpinion } from "../opinions -section/OpinionsSection";

export interface IAnswers {
    question: string;
    answer: string;
}

const ANSWERS = [
    {
        question: 'Jak mogę się zapisać na kursy programowania?',
        answer: 'Zapisów można dokonać telefonicznie 796 526 375 lub mailowo kontakt@quokka-school.pl',
    },
    {
        question: 'W jakiej formule odbywają się zajęcia?',
        answer: 'Na chwilę obecną zajęcia odbywają się wyłącznie w formie zdalnej.',
    },
    {
        question: 'Jak przebiegają zajęcia?',
        answer: 'W zależności od stopnia zaawansowania grupy i omawianych aktualnie zagadnień zajęcia mogą przyjmować formę wykładu, live coding lub wspólnej pracy przy tworzeniu projektu. Po każdym spotkaniu będzie możliwość indywidualnego rozbudowania tworzonego na nim projektu we własnym zakresie (samodzielne ćwiczenie "domowe").',
    },
    {
        question: 'Jakie są terminy zajęć?',
        answer: 'Wszelkie terminy zajęć są ustalane indywidualnie dla grupy. Zbierane są preferencje uczestników kursu co do dostępności i z ich uwzględnieniem wyznaczane są terminy. Terminy dla każdych zajęć są ustalane na początku kursu i każdy uczestnik dostanie dokładną ich rozpiskę.',
    },
    {
        question: 'Ile kosztuje kurs?',
        answer: 'W zależności od stopnia jego zaawansowania cena będzie się różnić. Dokładne kwoty znajdują się na stronie ze szegółami dla danego kursu. W razie potrzeby uzyskania dokładniejszych informacji zachęcamy do bezpośredniego kontaktu telefonicznego 796 526 375 lub mailowego kontakt@quokka-school.pl',
    },
    {
        question: 'Jak dokonuje się płatności?',
        answer: 'Płatności dokonuje się przelewem lub BLIK\'iem za aktualny miesiąc z terminem do 5 dnia bieżącego miesiąca.',
    },
    {
        question: 'Czy mogę otrzymać fakturę za kurs?',
        answer: 'Tak, do każdej z płatności dajemy możliwość otrzymania faktury dla osoby fizycznej, jak i na dane firmowe.',
    },
]

const AnswersSection = () => {
    const [answers, setAnswers] = useState<IAnswers[]>([]);

    useEffect(() => {
        fetch('data/answers.json')
            .then((res) => res.json())
            .then((data) => setAnswers(data))
    }, []);

    return (
        <div className="answers-section">
            <div className="container">
                <Heading className="answers-section__title" tag="h3" size="lg" data-aos="fade-up">Najczęściej zadawane pytania</Heading>

                {answers.map((item, index) =>
                    <CardCollapse key={index} question={item.question} answer={item.answer}/>
                )}
            </div>
        </div>
    )
}

export default AnswersSection;
