import React from 'react';
import { IAuxProps } from '../../shared/interfaces/IAuxProps';
import './Button.scss';

export interface IButtonProps extends IAuxProps, React.HTMLAttributes<HTMLButtonElement> {
    type?: 'fill' | 'stroked' | 'icon';
    hasIcon?: boolean;
    clickAction?: any;
    style?: React.CSSProperties;
}

const Button = ({ type, hasIcon, clickAction, children, style, ...props }: IButtonProps) => {
    return (
        <button
            className={`button button--${type ?? 'fill'} ${hasIcon ? 'button--has-icon': ''}`}
            onClick={clickAction ?? null}
            style={style}
            {...props}
        >
            { children }
        </button>
    )
}

export default Button;
