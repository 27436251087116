import React, { ReactElement } from 'react';
import Heading from '../heading/Heading';
import './Header.scss';

export interface IHeaderProps {
    icon: ReactElement<any, any>;
    title: string;
}

const Header = ({ icon, title }: IHeaderProps) => {
    return (
        <div className="header">
            <div className="header__icon">
                {icon}
            </div>
            <Heading className="header__title" tag="h4" size="sm">{title}</Heading>
        </div>
    );
}

export default Header;
