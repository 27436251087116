import './Announcement.scss';
import EKorepetycjeLogo from '../../assets/e-korepetycje-logo.svg';

const Announcement = () => {
    return (
        <a className="announcement" href="https://www.e-korepetycje.net/jacu/informatyka" target="_blank">
            <img src={EKorepetycjeLogo} alt=""/>
        </a>
    )
}

export default Announcement;
