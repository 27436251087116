import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './style.scss';

import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles

import 'atropos/css';

AOS.init();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
