import { IAuxProps } from '../../shared/interfaces/IAuxProps';
import React, { ElementType } from 'react';
import './Heading.scss';

export interface IHeadingProps extends IAuxProps, React.HTMLAttributes<HTMLHeadingElement> {
    tag?: ElementType;
    size?: 'xl' | 'lg' | 'md' | 'sm' | 'xs';
    fontWeight?: 'thin' | 'regular' | 'medium' | 'semi-bold' | 'bold' | 'extra-bold';
}

const Heading = ({
    tag: Tag = 'h1',
    size: size = 'lg',
    fontWeight: fontWeight = 'bold',
    children,
    ...props
}: IHeadingProps
) => {
    return (
        <Tag
            {...props}
            className={`${props.className} heading heading--${size} heading--${fontWeight}`}
        >
            {children}
        </Tag>
    )
}

export default Heading;
