import React, { useState } from 'react';
import Heading from '../heading/Heading';
import './CardCollapse.scss';
import { IAuxProps } from "../../shared/interfaces/IAuxProps";
import Paragraph from "../paragraph/Paragraph";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

export interface ICardCollapseProps extends IAuxProps {
    question: string;
    answer?: string;
}

const CardCollapse = ({
    question,
    answer,
    children,
}: ICardCollapseProps) => {
    const [collapse, setCollapse] = useState<boolean>(false);

    const handleCollapseClick = () => {
        setCollapse(!collapse);
    }

    return (
        <div className={`card-collapse ${collapse ? 'card-collapse--collapsed' : ''}`} >
            <div onClick={handleCollapseClick} className="card-collapse__question">
                <Heading tag="h4" size="sm">{question}</Heading>

                <div onClick={handleCollapseClick} className="card-collapse__collapse">
                    <FontAwesomeIcon icon={faChevronDown}/>
                </div>
            </div>

            <div className='card-collapse__answer'>
                {
                    answer ?
                        <Paragraph
                            className="card-collapse__paragraph"
                            fontFamily="content"
                        >
                            {answer}
                        </Paragraph>
                    : children
                }
            </div>
        </div>
    );
}

export default CardCollapse;
