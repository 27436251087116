import React from 'react';
import { ENavbarRef } from './shared/enums/ENavbarRef';
import Navbar from './components/navbar/Navbar';
import Section from './components/section/Section';
import StartSection from './components/sections/start-section/StartSection';
import ContactSection from './components/sections/contact-section/ContactSection';
import AboutSection from './components/sections/about-section/AboutSection';
import Announcement from './components/announcement/Announcement';
import LevelsSection from './components/sections/levels-section/LevelsSection';
import QuestionsSection from './components/sections/questions-section/QuestionsSection';
import BenefitsSection from './components/sections/benefits-section/BenefitsSection';
import OpinionsSection from './components/sections/opinions -section/OpinionsSection';
import AnswersSection from "./components/sections/answers-section/AnswersSection";

const App = () => {
    return (
        <div className="app">
            <Announcement />
            <Navbar/>

            <Section name={ENavbarRef.Start}>
                <StartSection/>
                <AboutSection/>
            </Section>

            <Section name={ENavbarRef.Scope}>
                <LevelsSection />
            </Section>

            <Section name={ENavbarRef.Offert}>
                <QuestionsSection />
            </Section>

            <Section name={ENavbarRef.Offert}>
                <BenefitsSection />
            </Section>

            <Section name={ENavbarRef.Opinions}>
                <OpinionsSection />
            </Section>

            <Section name={ENavbarRef.Answers}>
                <AnswersSection/>
            </Section>

            <Section name={ENavbarRef.Contact}>
                <ContactSection/>
            </Section>
        </div>
    );
}

export default App;
