import React, { useEffect, useState } from 'react';
import Heading from '../../heading/Heading';
import './LevelsSection.scss';
import CardScope from '../../card-scope/CardScope';

export interface ICardLevel {
    image: string;
    imageAlt: string;
    title: string;
    maxGroupCount: number;
    meetingCountPerWeek: number;
    meetingTime: number;
    description: string[];
    pdfLink: string;
}

const LevelsSection = () => {
    const [cardsLevels, setCardsLevels] = useState<ICardLevel[]>([]);

    useEffect(() => {
        fetch('data/cards-levels.json')
            .then((res) => res.json())
            .then((data) => setCardsLevels(data))
    }, []);

    return (
        <div className="levels-section container">
            <Heading className="levels-section__title" tag="h3" size="lg" data-aos="fade-up">Pomagamy na każdym poziomie!</Heading>
            <Heading className="levels-section__subtitle" tag="h4" size="md" fontWeight="thin" data-aos="fade-up" data-aos-delay="150">Poznaj naszą ofertę</Heading>

            <div className="levels-section__cards">
                {cardsLevels.map((item, index) =>
                    <CardScope
                        key={index}
                        index={index}
                        image={item.image}
                        imageAlt={item.imageAlt}
                        title={item.title}
                        maxGroupCount={item.maxGroupCount}
                        meetingCountPerWeek={item.meetingCountPerWeek}
                        meetingTime={item.meetingTime}
                        description={item.description}
                        pdfLink={item.pdfLink}
                    />
                )}
            </div>
        </div>
    );
}

export default LevelsSection;
