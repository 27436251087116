import React from 'react';
import Heading from '../../heading/Heading';
import Paragraph from '../../paragraph/Paragraph';
import './BenefitsSection.scss';
import Quokka6 from '../../../assets/quokka6.jpg';
import Quokka9 from '../../../assets/quokka9.jpg';
import Quokka8 from '../../../assets/quokka8.jpg';
import Quokka10 from '../../../assets/quokka10.jpg';

const BenefitsSection = () => {
    return (
        <div className="benefits-section container container--narrow">
            <div className="benefit">
                <div className="benefit__image" style={{ backgroundImage: `url(${Quokka6})` }} data-aos="fade-up" data-aos-delay="150"></div>
                <div className="benefit__desc" data-aos="fade-up" data-aos-delay="300">
                    <Heading className="benefit__title" tag="h2" size="md">Tworzenie praktycznych projektów</Heading>
                    <Paragraph className="benefit__paragraph" fontFamily="content">Stawiamy na naukę przez
                        działanie. Niezależnie od tego, czy dopiero zaczynasz swoją przygodę z programowaniem, czy też
                        chcesz doskonalić swoje umiejętności, będziesz miał okazję pracować nad realistycznymi
                        projektami. Pozwoli Ci to na zdobycie praktycznego doświadczenia i rozwinięcie swojego
                        portfolio.</Paragraph>
                </div>
            </div>

            <div className="benefit">
                <div className="benefit__desc" data-aos="fade-up" data-aos-delay="150">
                    <Heading className="benefit__title" tag="h2" size="md">Praca w zespole</Heading>
                    <Paragraph className="benefit__paragraph" fontFamily="content">Stawiamy na naukę przez działanie.
                        Niezależnie od tego, czy dopiero zaczynasz swoją przygodę z programowaniem, czy też chcesz
                        doskonalić swoje umiejętności, będziesz miał okazję pracować nad realistycznymi projektami.
                        Pozwoli Ci to na zdobycie praktycznego doświadczenia i rozwinięcie swojego
                        portfolio.</Paragraph>
                </div>
                <div className="benefit__image" style={{ backgroundImage: `url(${Quokka9})` }} data-aos="fade-up" data-aos-delay="300"></div>
            </div>

            <div className="benefit">
                <div className="benefit__image" style={{ backgroundImage: `url(${Quokka8})` }} data-aos="fade-up" data-aos-delay="150"></div>
                <div className="benefit__desc" data-aos="fade-up" data-aos-delay="300">
                    <Heading className="benefit__title" tag="h2" size="md">Nauka pisania dobrego kodu</Heading>
                    <Paragraph className="benefit__paragraph" fontFamily="content">Kodowanie to nie tylko kwestia
                        działającego programu, ale również czytelnego i zrozumiałego kodu. Uczymy naszych studentów
                        tworzenia "czystego" kodu, który jest łatwy do zrozumienia, łatwy w utrzymaniu i napisany
                        zgodnie z najlepszymi praktykami branżowymi. Dzięki temu nie tylko osiągniesz lepsze rezultaty,
                        ale również staniesz się bardziej efektywnym programistą.</Paragraph>
                </div>
            </div>

            <div className="benefit">
                <div className="benefit__desc" data-aos="fade-up" data-aos-delay="150">
                    <Heading className="benefit__title" tag="h2" size="md">Zajęcia z praktykiem programistą</Heading>
                    <Paragraph className="benefit__paragraph" fontFamily="content">Stawiamy na naukę przez działanie.
                        Niezależnie od tego, czy dopiero zaczynasz swoją przygodę z programowaniem, czy też chcesz
                        doskonalić swoje umiejętności, będziesz miał okazję pracować nad realistycznymi projektami.
                        Pozwoli Ci to na zdobycie praktycznego doświadczenia i rozwinięcie swojego
                        portfolio.</Paragraph>
                </div>
                <div className="benefit__image" style={{ backgroundImage: `url(${Quokka10})` }} data-aos="fade-up" data-aos-delay="300"></div>
            </div>
        </div>
    );
}

export default BenefitsSection;
