import React from 'react';
import './ContactSection.scss';
import Heading from '../../heading/Heading';
import Header from '../../header/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import QuokkaSchoolLogo from './../../../assets/quokka-school-logo.svg';
import Paragraph from '../../paragraph/Paragraph';

const ContactSection = () => {
    return (
        <div className="contact-section container">
            <div className="contact-section__contact">
                <Heading className="contact-section__title" tag="h3" size="lg" data-aos="fade-up" data-aos-delay="150">Kontakt</Heading>

                <Paragraph className="contact-section__paragraph" fontFamily="content" data-aos="fade-up" data-aos-delay="300">
                    Chciałyś się zapisać na kurs? 🤝 Może nie udało Ci się znaleźć czegoś na naszej stronie i potrzebujesz dodatkowych informacji? 😥 Zapraszamy do bezpośredniego kontaktu! ✉️
                </Paragraph>

                <div className="contact-section__cards">
                    <div className="contact-section__card" data-aos="fade-up" data-aos-delay="450">
                        <Header
                            icon={<FontAwesomeIcon icon={faPhone} className="icon"/>}
                            title="Zadzwoń"
                        />
                        <a href="tel:796526375" className="card__action">
                            796 526 375
                        </a>
                    </div>

                    <div className="contact-section__card" data-aos="fade-up" data-aos-delay="600">
                        <Header
                            icon={<FontAwesomeIcon icon={faEnvelope} className="icon"/>}
                            title="Napisz"
                        />
                        <a href="mailto:kontakt@quokka-school.pl" className="card__action">
                            kontakt@quokka-school.pl
                        </a>
                    </div>
                </div>
            </div>

            <footer className="contact-section__footer">
                <img className="footer__logo" src={QuokkaSchoolLogo} alt="Quokka School Logo - Szkoła Programowania i Mentoring"/>
                <p className="footer__text">&copy; {new Date().getFullYear()} Quokka School - Szkoła Programowania</p>
                <p className="footer__text">Wszelkie prawa zastrzeżone</p>
                <div className="footer__links">
                    <a href="https://quokka-school.pl/pliki/regulamin.pdf" target="_blank">
                        Regulamin
                    </a>
                    <a href="https://quokka-school.pl/pliki/rodo.pdf" target="_blank">
                        Rodo
                    </a>
                    <a href="https://quokka-school.pl/pliki/reklamacja.pdf" target="_blank">
                        Reklamacja
                    </a>
                </div>
            </footer>
        </div>
    );
}

export default ContactSection;
