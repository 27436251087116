export enum ENavbarRef {
    Start = 'start',
    Scope = 'scope',
    Offert = 'offert',
    Opinions = 'opinions',
    Answers = 'answers',
    // Projects = 'projects',
    // Mentoring = 'mentoring',
    Contact = 'contact',
}
