import React from 'react';
import Heading from '../../heading/Heading';
import Paragraph from '../../paragraph/Paragraph';
import './QuestionsSection.scss';

const QuestionsSection = () => {
    return (
        <div className="questions-section container container--shrink">
            <div className="question" data-aos="fade-up">
                <div className="question__step">
                    <span>1</span>
                </div>
                <div className="question__desc">
                    <Heading className="question-section__title" tag="h4" size="lg">Nie wiesz co dalej?</Heading>
                    <Paragraph fontFamily="content">Uczenie się "na własną rękę" potrafi znudzić i stworzyć w pewnym
                        momencie poczucie "ściany". Pomożemy Ci ją pokonać!</Paragraph>
                </div>
            </div>

            <div className="question question--center" data-aos="fade-up" data-aos-delay="150">
                <div className="question__step">
                    <span>2</span>
                </div>
                <div className="question__desc">
                    <Heading className="question-section__title" tag="h4" size="lg">Brak ścieżki rozwoju?</Heading>
                    <Paragraph fontFamily="content">Samemu ciężko określić które rzeczy są istotne i ważne, a które
                        mniej. Wskażemy Ci drogę i poprowadzimy w konkretnym kierunku.</Paragraph>
                </div>
            </div>

            <div className="question question--end" data-aos="fade-up" data-aos-delay="300">
                <div className="question__step">
                    <span>3</span>
                </div>
                <div className="question__desc">
                    <Heading className="question-section__title" tag="h4" size="lg">Boisz się matury lub
                        egzaminów?</Heading>
                    <Paragraph fontFamily="content">Bez wątpienia tego typu wydarzenia potrafią przysporzyć wiele stresu
                        i obaw. Pomożemy Ci się do nich przygotować pokazując od razu zastosowanie
                        praktyczne uczonych rzeczy.</Paragraph>
                </div>
            </div>
        </div>
    );
}

export default QuestionsSection;
